import React from "react"
import PostsListItem from "./PostsListItem"

const PostsList = ({ posts }) => {
  return (
    <ul className="container px-6 mx-auto mt-10 sm:grid sm:grid-cols-2 lg:grid-cols-3 sm:gap-x-8 sm:gap-y-16">
      {posts.nodes.map((node, index) => (
        <PostsListItem key={index} post={node} />
      ))}
    </ul>
  )
}

export default PostsList
