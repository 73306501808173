import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { CommentCount } from "disqus-react"
import { FaRegCommentDots, FaRegCalendarAlt } from "react-icons/fa"

const PostsListItem = ({ post }) => {
  const { excerpt, frontmatter, id } = post
  const { title, featuredImage, slug, date } = frontmatter

  const disqusShortname = process.env.GATSBY_DISQUS_NAME
  const disqusConfig = {
    identifier: id,
    title: title,
    url: `https://www.imgerd.nl/blog/${slug}/`,
  }

  return (
    <li key={title} className="flex flex-col mt-20 sm:mt-0">
      <div className="text-sm text-secondary-text">
        <FaRegCalendarAlt className="inline" /> {date}
      </div>
      <Link to={`/blog/${slug}/`}>
        <Img fluid={featuredImage.childImageSharp.fluid} className="mt-5" />
        <h3 className="mt-5 text-xl font-bold uppercase hover:text-gray-600">
          {title}
        </h3>
      </Link>
      <p className="mt-5 leading-loose">{excerpt}</p>
      <Link to={`/blog/${slug}/`}>
        <button className="mt-5 font-bold bg-primary hover:underline">
          Lees blog --&gt;
        </button>
      </Link>
      <div className="mt-5 text-sm text-secondary-text">
        <FaRegCommentDots className="inline" />{" "}
        <CommentCount shortname={disqusShortname} config={disqusConfig} />
      </div>
    </li>
  )
}

export default PostsListItem
