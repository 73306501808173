import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import Img from "gatsby-image"
import SEO from "../components/SEO"
import StyledHeading from "../components/StyledHeading"
import PostsList from "../components/PostsList"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO
      title="Een blogger zonder plan, maar met een mening"
      description="Ik ben Imgerd. Eigenzinnig, eigenwijs en eigenaardig eigentijds. Op deze websites deel ik mijn gedachten, columns en blogs in de hoop er rijk mee te worden."
    />

    <div className="grid-cols-2 px-6 lg:mt-10 lg:grid">
      <Img fluid={data.welcome.childImageSharp.fluid} alt="" />
      <div className="justify-center lg:flex lg:flex-col">
        <h1 className="mt-5 text-2xl font-bold uppercase xl:text-4xl">
          Aangenaam, ik ben Imgerd
        </h1>
        <p className="mt-5 leading-loose sm:ml-12 lg:max-w-xl xl:text-xl">
          Eigenzinnig, eigenwijs en eigenaardig eigentijds. Ideeën over alles,
          maar geen eigen site. Dat klopte niet. Daarom deze website waarop ik
          alles deel wat ik denk.
        </p>
        <Link to="/over-moi/">
          <button className="mt-5 font-bold xl:text-xl bg-primary hover:underline">
            Lees meer over moi
          </button>
        </Link>
      </div>
    </div>

    <div className="container px-6 mx-auto mt-32">
      <StyledHeading
        tag="h2"
        text="Deze zou ik zelf eerst lezen"
        size="text-2xl"
      />
      <Link to={`/blog/${data.featuredPost.nodes[0].frontmatter.slug}/`}>
        <Img
          fluid={
            data.featuredPost.nodes[0].frontmatter.featuredImage.childImageSharp
              .fluid
          }
          alt=""
          className="mt-20 sm:hidden"
        />
        <Img
          fluid={
            data.featuredPost.nodes[0].frontmatter.largeImage.childImageSharp
              .fluid
          }
          alt=""
          className="hidden mt-10 sm:block"
        />
      </Link>
      <div className="mt-5">
        <Link to={`/blog/${data.featuredPost.nodes[0].frontmatter.slug}/`}>
          <h3 className="text-xl font-bold uppercase hover:text-gray-600">
            {data.featuredPost.nodes[0].frontmatter.title}
          </h3>
        </Link>
        <Link to={`/blog/${data.featuredPost.nodes[0].frontmatter.slug}/`}>
          <button className="mt-5 font-bold bg-primary hover:underline">
            Lees dit blog
          </button>
        </Link>
      </div>
    </div>

    <div className="container px-6 mx-auto mt-32">
      <StyledHeading
        tag="h2"
        text="Ik schreef nog meer blogs"
        size="text-2xl"
      />
    </div>
    <PostsList posts={data.posts} />
    <div className="flex justify-center mt-20">
      <Link to="/blog/">
        <button className="font-bold bg-primary hover:underline">
          Ik wil meer blogs zien
        </button>
      </Link>
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query BlogPostsQuery {
    featuredPost: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1
    ) {
      nodes {
        id
        excerpt(pruneLength: 150)
        frontmatter {
          title
          date(formatString: "DD/MM/YYYY")
          slug
          featuredImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          largeImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        id
      }
    }
    posts: allMarkdownRemark(
      skip: 1
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 3
    ) {
      nodes {
        id
        excerpt(pruneLength: 150)
        frontmatter {
          title
          date(formatString: "DD/MM/YYYY")
          slug
          featuredImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        id
      }
    }
    welcome: file(
      relativePath: { eq: "media/schreeuwlelijk-1-testkopie.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    featuredsmall: file(
      relativePath: { eq: "media/karel-is-niet-vegetarisch-vierkant.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    featuredlarge: file(
      relativePath: { eq: "media/karel-is-niet-vegetarisch-groot.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
